import React from "react";

import UnderConstruction from "../components/UnderConstruction";
import Footer from "../components/Footer";

export default () => (
  <div>
    <UnderConstruction></UnderConstruction>
    <Footer></Footer>
  </div>
);
